<template>
	<v-form v-if="value" ref="form" v-model="validationState">
		<v-layout row wrap>
			<v-flex px-1 xs12 lg6>
				<w-text-input v-model="bankAccount.bank_name" :label="$t('customer.bank.bank_name')" maxlength="191" :readonly="readonly" required validate-on-blur />
			</v-flex>
			<v-flex px-1 xs12 lg6>
				<w-text-input
					v-model="bankAccount.bank_address"
					:label="$t('customer.bank.bank_address')"
					maxlength="191"
					:readonly="readonly"
					required
					validate-on-blur
				/>
			</v-flex>
			<v-flex px-1 xs12 lg6>
				<w-text-input
					v-model="bankAccount.bic"
					:label="$t('customer.bank.bic')"
					maxlength="11"
					:readonly="readonly"
					required
					:rules="[rules.bic]"
					validate-on-blur
				/>
			</v-flex>
			<v-flex px-1 xs12 lg6>
				<v-layout align-center row>
					<w-text-input
						v-model="bankAccount.iban"
						:label="$t('customer.bank.iban')"
						mask="AA## NNNN NNNN NNNN NNNN NNNN NNNN NNNN NN"
						maxlength="34"
						:readonly="readonly"
						required
						:rules="[rules.iban]"
						validate-on-blur
						@blur="onBlur()"
					/>
					<w-btn flat small @click="copy(account.iban, $t('customer.bank.iban'))">{{ $t('customer.bank.copy_iban') }}</w-btn>
				</v-layout>
			</v-flex>
			<v-flex px-1 xs12 sm6 lg4 xl2>
				<v-layout align-center row>
					<w-text-input
						v-model="iban.countryCode"
						:label="$t('customer.bank.iban_country_code')"
						maxlength="2"
						:readonly="readonly"
						required
						validate-on-blur
						@input="updateIBANString()"
					/>
					<w-btn flat small @click="copy(iban.countryCode, $t('customer.bank.iban_country_code'))">{{ $t('actions.copy') }}</w-btn>
				</v-layout>
			</v-flex>
			<v-flex px-1 xs12 sm6 lg4 xl2>
				<v-layout align-center row>
					<w-text-input
						v-model="iban.checkDigit"
						:label="$t('customer.bank.iban_check_digit')"
						maxlength="2"
						:readonly="readonly"
						required
						validate-on-blur
						@input="updateIBANString()"
					/>
					<w-btn flat small @click="copy(iban.checkDigit, $t('customer.bank.iban_check_digit'))">{{ $t('actions.copy') }}</w-btn>
				</v-layout>
			</v-flex>
			<v-flex px-1 xs12 sm6 lg4 xl2>
				<v-layout align-center row>
					<w-text-input
						v-model="iban.bankCode"
						:label="$t('customer.bank.iban_bank_code')"
						maxlength="30"
						:readonly="readonly"
						required
						validate-on-blur
						@input="updateIBANString()"
					/>
					<w-btn flat small @click="copy(iban.bankCode, $t('customer.bank.iban_bank_code'))">{{ $t('actions.copy') }}</w-btn>
				</v-layout>
			</v-flex>
			<v-flex px-1 xs12 sm6 lg4 xl2>
				<v-layout align-center row>
					<w-text-input
						v-model="iban.branchCode"
						:label="$t('customer.bank.iban_branch_code')"
						maxlength="30"
						:readonly="readonly"
						required
						validate-on-blur
						@input="updateIBANString()"
					/>
					<w-btn flat small @click="copy(iban.branchCode, $t('customer.bank.iban_branch_code'))">{{ $t('actions.copy') }}</w-btn>
				</v-layout>
			</v-flex>
			<v-flex px-1 xs12 sm6 lg4 xl2>
				<v-layout align-center row>
					<w-text-input
						v-model="iban.accountNumber"
						:label="$t('customer.bank.iban_account_number')"
						maxlength="30"
						:readonly="readonly"
						required
						validate-on-blur
						@input="updateIBANString()"
					/>
					<w-btn flat small @click="copy(iban.accountNumber, $t('customer.bank.iban_account_number'))">{{ $t('actions.copy') }}</w-btn>
				</v-layout>
			</v-flex>
			<v-flex px-1 xs12 sm6 lg4 xl2>
				<v-layout align-center row>
					<w-text-input
						v-model="iban.nationalCheckDigit"
						:label="$t('customer.bank.iban_national_check_digit')"
						maxlength="30"
						:readonly="readonly"
						required
						validate-on-blur
						@input="updateIBANString()"
					/>
					<w-btn flat small @click="copy(iban.nationalCheckDigit, $t('customer.bank.iban_national_check_digit'))">{{ $t('actions.copy') }}</w-btn>
				</v-layout>
			</v-flex>
		</v-layout>
	</v-form>
</template>

<script>
import { BIC, IBAN } from 'ibankit'

import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/VendorSettings/VendorsSettingsModuleGuard'

export default {
	name: 'DetailedBankForm',
	mixins: [CustomersManagerModuleGuard],
	props: {
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			iban: {
				countryCode: null,
				checkDigit: null,
				bankCode: null,
				branchCode: null,
				accountNumber: null,
				nationalCheckDigit: null
			},
			rules: {
				bic: value => {
					if (!value || !value.trim()) {
						return true
					}
					return BIC.isValid(value) || this.$t('customer.bank.errors.bic_not_valid')
				},
				iban: value => {
					if (!value || !value.trim()) {
						return true
					}
					return IBAN.isValid(value) || this.$t('customer.bank.errors.iban_not_valid')
				}
			}
		}
	},
	computed: {
		bankAccount: {
			get: function () {
				return this.value
			},
			set: function (value) {
				this.$emit('input', value)
			}
		},
		validationState: {
			get: function () {
				return null
			},
			set: function () {
				this.$emit('update:is-valid', this.$refs.form?.validate())
			}
		}
	},
	watch: {
		value: {
			handler: function () {
				if (this.value) {
					this.createIBAN()
				}
			}
		}
	},
	mounted: function () {
		this.createIBAN()
	},
	methods: {
		copy: function (stringToCopy, field) {
			this.appService.copyToClipboard(stringToCopy)
			this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, field + ' ' + this.$t('customer.bank.messages.copied'))
		},
		createIBAN: function () {
			if (IBAN.isValid(this.value.iban)) {
				const iban = new IBAN(this.value.iban)

				this.iban = {
					countryCode: iban.getCountryCode(),
					checkDigit: iban.getCheckDigit(),
					bankCode: iban.getBankCode(),
					branchCode: iban.getBranchCode(),
					accountNumber: iban.getAccountNumber(),
					nationalCheckDigit: iban.getNationalCheckDigit()
				}
			} else {
				this.iban = {
					countryCode: null,
					checkDigit: null,
					bankCode: null,
					branchCode: null,
					accountNumber: null,
					nationalCheckDigit: null
				}
			}
		},
		onBlur: function () {
			this.createIBAN()
		},
		reset: function () {
			if (this.$refs.hasOwnProperty('form')) {
				this.$refs.form.reset()
			}
		},
		updateIBANString: function () {
			let iban = ''
			if (this.iban.countryCode) {
				iban += this.iban.countryCode
			}
			if (this.iban.checkDigit) {
				iban += this.iban.checkDigit
			}
			if (this.iban.bankCode) {
				iban += this.iban.bankCode
			}
			if (this.iban.branchCode) {
				iban += this.iban.branchCode
			}
			if (this.iban.accountNumber) {
				iban += this.iban.accountNumber
			}
			if (this.iban.nationalCheckDigit) {
				iban += this.iban.nationalCheckDigit
			}
			this.bankAccount.iban = iban
		}
	}
}
</script>
